<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link v-if="$can('generate_physical')" :to="{name: 'Generate Voucher Fisik Detail', params: { id: this.$route.params.id }}" class="btn btn-primary"><i class="fa fa-plus"></i> Generate</router-link>&nbsp;
          <button v-if="$can('export_physical')" class="btn btn-success" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Voucher Code, Voucher Name"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Voucher Code, Voucher Name ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="start_from-slot" slot-scope="prop">
          <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
            >{{ prop.rowData.start_from }}</span>
        </template>
        <template slot="end_to-slot" slot-scope="prop">
          <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
            >{{ prop.rowData.end_to }}</span>
        </template>
        <template slot="is_buyed-slot" slot-scope="prop">
          <span v-if="prop.rowData.is_buyed === true" class="ui green label"><i class="fa fa-check"></i></span>
          <span v-else class="ui red label"><i class="fa fa-times"></i></span>
        </template>
        <template slot="buyed_by-slot" slot-scope="prop">
          <span>{{ prop.rowData.buyed_by }}</span>
        </template>
        <template slot="is_redeemed-slot" slot-scope="prop">
          <span v-if="prop.rowData.is_redeemed === true" class="ui green label"><i class="fa fa-check"></i></span>
          <span v-else class="ui red label"><i class="fa fa-times"></i></span>
        </template>
        <!-- <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button class="btn btn-info"
              v-if="$can('detail_physical')"
              @click="onAction('detail-item', prop.rowData, prop.rowIndex)">
              Detail
            </button> &nbsp;
            <button class="btn btn-danger"
              v-if="prop.rowData.is_buyed === false && $can('delete_physical')"
              @click="onAction('delete-item', prop.rowData, prop.rowIndex)">
              Delete
            </button>
          </div>
        </template> -->
        <template slot="redeemed_by-slot" slot-scope="prop">
          <span>{{ prop.rowData.redeemed_by }}</span>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
    <b-modal title="Detail Voucher Fisik" size="lg" v-model="modal" @ok="modal = false">
      <h6>Name</h6>
      <div class="mb-3"><strong>{{ this.detail.name }}</strong></div>
      <h6>Code</h6>
      <div class="mb-3"><strong>{{ this.detail.code }}</strong></div>
      <h6>Start From</h6>
      <div class="mb-3"><strong>{{ this.detail.start_from }}</strong></div>
      <h6>End To</h6>
      <div class="mb-3"><strong>{{ this.detail.end_to }}</strong></div>
      <h6>Amount</h6>
      <div class="mb-3"><strong>{{ this.detail.amount }}</strong></div>
      <h6>Redeem Type</h6>
      <div class="mb-3"><strong>{{ this.detail.redeem_type }}</strong></div>
      <h6>User Type</h6>
      <div class="mb-3"><strong>{{ this.detail.user_type }}</strong></div>
      <h6 v-if="this.detail.user_type === 'Register At'">Register Start</h6>
      <div v-if="this.detail.user_type === 'Register At'" class="mb-3"><strong>{{ this.detail.register_start }}</strong></div>
      <h6 v-if="this.detail.user_type === 'Register At'">Register End</h6>
      <div v-if="this.detail.user_type === 'Register At'" class="mb-3"><strong>{{ this.detail.register_end }}</strong></div>
      <h6 v-if="this.detail.user_type === 'Last Time Buy'">Day</h6>
      <div v-if="this.detail.user_type === 'Last Time Buy'" class="mb-3"><strong>{{ this.detail.day }}</strong></div>
      <h6>Price</h6>
      <div class="mb-3"><strong>{{ this.detail.price }}</strong></div>
      <h6>Promotion</h6>
      <div class="mb-3"><strong>{{ this.detail.Promotion }}</strong></div>
      <h6>Organic</h6>
      <div class="mb-3">
        <span v-if="this.detail.is_organic == true"><i class="fa fa-check"></i></span>
        <span v-else class="ui red label"><i class="fa fa-times"></i></span>
      </div>
      <h6>Sold</h6>
      <div class="mb-3">
        <span v-if="this.detail.is_buyed == true"><i class="fa fa-check"></i></span>
        <span v-else class="ui red label"><i class="fa fa-times"></i></span>
      </div>
      <h6>Sold To</h6>
      <div class="mb-3"><strong>{{ this.detail.buyed_by }}</strong></div>
      <h6>Generated</h6>
      <div class="mb-3"><strong>{{ this.detail.created_at }} - {{ this.detail.generated_by }}</strong></div>
      <h6>Redeemed</h6>
      <div class="mb-3">
        <span v-if="this.detail.is_redeemed == 'Yes'"><i class="fa fa-check"></i></span>
        <span v-else class="ui red label"><i class="fa fa-times"></i></span>
      </div>
      <h6>Redeem By</h6>
      <div class="mb-3"><strong>{{ this.detail.redeemed_by }}</strong></div>
    </b-modal>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import moment from 'moment/src/moment'
  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        isLoading: false,
        filterText: '',
        modal: false,
        detail: [],
        timeNow: moment().format('YYYY-MM-DD hh:mm:ss'),
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `physical-detail?hashedId=${this.$route.params.id}`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'vouchers.created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'production_code',
            title: 'Production Code'
          },
          {
            name: 'code',
            sortField: 'vouchers.code',
            title: 'Voucher Code'
          },
          {
            name: 'amount',
            sortField: 'voucher_settings.amount',
            title: 'Voucher Amount'
          },
          {
            name: 'name',
            sortField: 'voucher_settings.name',
            title: 'Voucher Name'
          },
          {
            name: 'start_from-slot',
            sortField: 'vouchers.start_from',
            title: 'Voucher Expired Start'
          },
          {
            name: 'end_to-slot',
            sortField: 'vouchers.end_to',
            title: 'Voucher Expired End'
          },
          {
            name: 'is_buyed-slot',
            sortField: 'vouchers.is_buyed',
            title: 'Sold',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          },
          {
            name: 'buyed_by-slot',
            title: 'Sold To'
          },
          {
            name: 'is_redeemed-slot',
            title: 'Redeemed',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          },
          {
            name: 'redeemed_by',
            title: 'Redeemed By'
          }
          // {
          //   name: 'actions-slot',
          //   title: 'Actions',
          //   titleClass: 'center aligned',
          //   dataClass: 'center aligned'
          // }
        ]
      }
    },
    methods: {
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher-physical/` + startDate + `/` + endDate + `?hashedId=${this.$route.params.id}`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      doFilter () {
        this.$events.fire('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.fire('filter-reset')
      },
      weight(value) {
        return value + ' Gram'
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      formatNumber (value) {
        return 'Rp.' + accounting.formatNumber(value, 2)
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == "detail-item") {
           this.$http.get(`physical/${data.id}?detail=true`)
              .then((result) => {
                let res = result.data.data
                this.detail = res
              })
          this.modal = true
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Voucher!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.delete(`physical/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Voucher Fisik has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Voucher Fisik was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
